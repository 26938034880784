html {
  height: 100% !important;
  /* margin-top: -40px; */
}

.App {
  height: 100% !important;
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* h3 {
    white-space: nowrap;
    margin-right: 0.5em;
    animation: ticker 15s linear infinite;
  }
  
  @keyframes ticker {
      0%   {transform: translateX(0%);}
      100% {transform: translateX(-100%);}
  } */

.fix {
  /*possible values: hidden, scroll, auto, initial, inherit*/
  overflow: hidden !important;
}

/* Landing.css or another CSS file */

.open-in-app-button {
  /* Button styling goes here */
  padding: 14px 20px;
  background-color: #005f45;
  border-radius: 5px;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

.open-in-app-button:hover {
  background-color: #00906a; /* Slightly darker pink for hover effect */
}
